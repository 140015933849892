<template>
  <b-card title="Number of Borrowers">
    <app-echart-bar :option-data="option" />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue";

export default {
  components: {
    BCard,
    AppEchartBar,
  },
  data() {
    return {
      option: {
        xAxis: [
          {
            type: "category",
            data: [
              "2022-48",
              "2022-49",
              "2022-50",
              "2022-51",
              "2022-52",
              "2022-53",
              "2022-54",
              "2022-55",
              "2022-56",
              "2022-57",
              "2022-58",
              "2022-59",
              "2022-60",
              "2022-61",
            ],
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: { show: false },
          },
        ],
        grid: {
          left: "40px",
          right: "30px",
          bottom: "30px",
        },
        series: [
          {
            name: "SA",
            type: "bar",
            stack: "advertising",
            data: [0, 6, 0, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            barMaxWidth: "20%",
            barMinWidth: "10px",
          },
          {
            name: "SF",
            type: "bar",
            stack: "advertising",
            data: [0, 8, 0, 7, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            barMaxWidth: "20%",
            barMinWidth: "10px",
          },
          {
            name: "SI",
            type: "bar",
            stack: "advertising",
            data: [0, 3, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            barMaxWidth: "20%",
            barMinWidth: "10px",
          },
          {
            name: "SP",
            type: "bar",
            stack: "advertising",
            data: [0, 3, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            barMaxWidth: "20%",
            barMinWidth: "10px",
          },
          {
            name: "SR",
            type: "bar",
            stack: "advertising",
            data: [0, 3, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            barMaxWidth: "20%",
            barMinWidth: "10px",
          },
          {
            name: "SS",
            type: "bar",
            stack: "advertising",
            data: [0, 2, 0, 4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            barMaxWidth: "20%",
            barMinWidth: "10px",
            itemStyle: {
              barBorderRadius: [10, 10, 0, 0],
            },
          },
        ],
      },
    };
  },
};
</script>
