<template>
  <b-card title="Loan Amount">
    <app-echart-bar :option-data="option" />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue";

export default {
  components: {
    BCard,
    AppEchartBar,
  },
  data() {
    return {
      option: {
        xAxis: [
          {
            type: "value",
          },
        ],
        yAxis: [
          {
            type: "category",
            data: [
              "2022-48",
              "2022-49",
              "2022-50",
              "2022-51",
              "2022-52",
              "2022-53",
              "2022-54",
            ],
            splitLine: { show: false },
          },
        ],
        grid: {
          left: "100px",
          right: "30px",
          bottom: "30px",
        },
        series: [
          {
            name: "SA",
            type: "bar",
            stack: "advertising",
            data: [0, 161000, 0, 100000, 0, 0, 0],
            barWidth: "30%",
          },
          {
            name: "SF",
            type: "bar",
            stack: "advertising",
            data: [0, 230000, 0, 177000, 0, 0, 0],
            barWidth: "30%",
          },
          {
            name: "SI",
            type: "bar",
            stack: "advertising",
            data: [0, 65000, 0, 55000, 0, 0, 0],
            barWidth: "30%",
          },
          {
            name: "SP",
            type: "bar",
            stack: "advertising",
            data: [0, 75000, 0, 50000, 0, 0, 0],
            barWidth: "30%",
          },
          {
            name: "SR",
            type: "bar",
            stack: "advertising",
            data: [0, 51000, 0, 100000, 0, 0, 0],
            barWidth: "30%",
          },
          {
            name: "SS",
            type: "bar",
            stack: "advertising",
            data: [0, 55000, 0, 90000, 0, 0, 0],
            barWidth: "30%",
            itemStyle: {
              barBorderRadius: [0, 10, 10, 0],
            },
          },
        ],
      },
    };
  },
};
</script>
